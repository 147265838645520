export interface GA4EventArgs {
    name: string
    properties: unknown
  }
  
export function publishAnalyticsEvent({ name, properties }: GA4EventArgs) {
    if (process.env.NODE_ENV === "development") {
      console.log(JSON.stringify({ name, properties }))
    } else {
      try {
        ;(window as any).gtag("event", name, properties)
      } catch (e) {
        console.error(e)
      }
    }
  }
  